// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import * as bootstrap from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

window.$ = jQuery;
window.bootstrap = bootstrap;


Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (el) {
    return new bootstrap.Tooltip(el)
  })

  const toastTriggerList = [].slice.call(document.querySelectorAll('.toast'))
  toastTriggerList.map(function (el) {
    return new bootstrap.Toast(el)
  })
});
